import { AircraftAvailabilityService } from "./../service/realService/aircraftAvailability";
import { factory } from "typescript";
import { IServiceFactory } from "../interface/IServicefactory";
import { ServiceFactory } from "./../factories/serviceFactory";

export class ServiceProvider {
  private factory: IServiceFactory = new ServiceFactory();
  userService = this.factory.getUserService();
  authenticationService = this.factory.getAuthenticationService();
  fleetService = this.factory.getFleetService();
  aircraftService = this.factory.getAircraftService();
  airportService = this.factory.getAirportService();
  couponService = this.factory.getCouponService();
  quotationService = this.factory.getQuotationService();
  itineraryService = this.factory.getItineraryService();
  flightService = this.factory.getFlightService();
  roleService = this.factory.getRoleService();
  bookingService = this.factory.getBookingService();
  aircraftAvailabilityService = this.factory.getAircraftAvailabilityService();
  operatorRequestService = this.factory.getOperatorRequestService();
  statsService = this.factory.getStatsService();
  commonService = this.factory.getCommonService();
  quoteService = this.factory.getQuoteService();
  rewardPointsUsageService = this.factory.getRewardPointsUsageService();
  aircraftSizeCategoryService = this.factory.getAircraftSizeCategoryService();
  feedService = this.factory.getFeedService();
}

export const serviceProvider = new ServiceProvider();
