import React, { createContext } from "react";
import { IAuthentication } from "../interface/IAuthentication";
import { IUserContext } from "../interface/IUserContext";
export const initAuthentication:IAuthentication={
    authenticated:false,
    session:null,
    token:""
};
export const initAuthenticationTrue:any={
    authenticated:true,
    session:null,
    token:""
};
const intialValue:IUserContext ={
    authentication:initAuthentication,
    setAuthentication: ()=>{
        return new Promise<any>((res,rej)=>{})
        
    }
}
export const UserContext=createContext(intialValue);
export const UserContexProvider: React.FC<IUserContext> = (props:any) => {
    return (
        <UserContext.Provider value={{...props}}>
            {props.children}
        </UserContext.Provider>
    );
}
export default UserContext;