import { Autocomplete } from '@mui/material';
import { maxFileSize } from "../configuration/config.json";
import { ValidationType } from "../enum/validationType";
import { IValidationResponse } from "../interface/IValidation";
/*
@:Valid Scenarios for mobile number
+91-9883443344
+91-9883443344
9883443344
09883443344
919883443344
0919883443344
+919883443344
+91-9883443344
0091-9883443344
+91 -9883443344
+91- 9883443344
+91 - 9883443344
0091 - 9883443344
7856128945
9998564723
022-24130000
080 25478965
0416-2565478
08172-268032
04512-895612
02162-240000
+91 9883443344
022-24141414  
*/
export class ValidationUtiltiy {

    private static readonly emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    private static readonly mobileRegex: RegExp = /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[6-9]\d{9}|(\d[ -]?){10}\d$/;
    private static readonly passwordRegex: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
    private static readonly panRegex: RegExp = /^([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    private static readonly adharRegex: RegExp = /^(\d{12})$/;
    private static readonly urlRegex: RegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    private static readonly imgType: RegExp = /\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF|WEBP|webp)$/;
    private static readonly docType: RegExp = /\.(doc|docx|pdf|txt|rtf|odt|jpg|jpeg|png|PDF|DOCX|DOC|JPG|JPEG|PNG)$/;
    private static readonly maxFileSize: number = maxFileSize;
    private static readonly resumeFiletype: RegExp = /\.(pdf|PDF)$/;
    public static isEmail(input: string): boolean {
        return this.emailRegex.test(input);
    }
    public static isNumeric(input: string): boolean {
        return !isNaN(parseFloat(input));
    }
    public static isInt(input: string): boolean {
        return !isNaN(parseInt(input));
    }
    public static isFloat(input: string): boolean {
        return !isNaN(parseFloat(input));
    }
    public static isValidImg(input: string) {
        return this.imgType.test(input);
    }
    public static isValidDoc(input: string) {
        return this.docType.test(input);
    }
    public static isNullOrEmpty(input: string): boolean {
        return input === "" || input === null;
    }
    public static isValidPan(input: string): boolean {
        return this.panRegex.test(input);
    }
    public static isValidAdhar(input: string): boolean {
        return this.adharRegex.test(input);
    }
    public static isValidInputByRegex(input: string, regex: RegExp): boolean {
        return regex.test(input);
    }
    public static isValidDate(input: string): boolean {
        return true;
    }
    public static isValidPassword(input: string): boolean {
        return this.passwordRegex.test(input);
    }
    public static isUrl(input: string): boolean {
        return this.urlRegex.test(input);
    }
    public static isValidResume(input: string): boolean {
        // debugger
        return this.resumeFiletype.test(input);
    }
    public static isValidDateRange(startDate: string, endDate: string): IValidationResponse {
        if (this.isValidDate(startDate) && this.isValidDate(endDate)) {
            return {
                isValid: false,
                msg: "Invalid date"
            };
        } else {
            return {
                isValid: false,
                msg: "Invalid date"
            };
        }
    }
    public static isValidFileSize(fileSize: number): boolean {
        const size = Math.round((fileSize / 1024));
        if (size > (this.maxFileSize * 1024)) {
            return false;
        }
        return true;
    }
    public static isValidMobileNumber(input: string | number): boolean {
        return this.mobileRegex.test(input.toString());
    }
    public static validateInputFileElement(event: React.ChangeEvent<HTMLInputElement>): void {
        const element = event.target;
        const selectedFile = element.files?.item(0) as Blob;
        if (!this.isValidFileSize(selectedFile.size)) {
            event.preventDefault();
            alert(`Size of file is not valid, please upload file less than ${maxFileSize} mb !`)
        }
    }
    public static onkeyUpNumber(event: React.KeyboardEvent<HTMLInputElement>) {
        var key = event.key;
        var convertedKey = isNaN(parseInt(key));
        if (convertedKey === true && key.length <= 1) {
            event.preventDefault();
        }
    }
    public static onkeyUpFloat(event: React.KeyboardEvent<HTMLInputElement>) {
        var key = event.key;
        var convertedKey = isNaN(parseFloat(key));
        if (convertedKey === true && key.length <= 1 && key !== '.') {
            event.preventDefault();
        }
    }
    public static onKeyUpText(event: React.KeyboardEvent<HTMLInputElement>) {
        var key = event.key;
        var convertedKey = isNaN(parseInt(key));
        if (convertedKey !== true && key.length <= 1 && key !== '.') {
            event.preventDefault();
        }
    }
    public static isValidSelectBoxValue(input: string): boolean {
        if (input !== undefined && input !== "" && input !== null && input !== '0') {
            return true;
        } else {
            return false;
        }
    }
    public static validateInput(input: string, type: ValidationType, isRequired: boolean): IValidationResponse {
        let response: IValidationResponse = {
            msg: "",
            isValid: true
        };
        if (ValidationType.Editor == type && !input) {
            if (isRequired) {
                response.msg = `description should not be blank !`;
                response.isValid = false;
            }
            return response;
        }
        else if (!this.isNullOrEmpty(input)) {
            switch (type) {
                case ValidationType.Email:
                    response.isValid = this.isEmail(input);
                    response.msg = response.isValid ? "" : "Invalid Email Id !";
                    break;
                case ValidationType.Mobile:
                    response.isValid = this.isValidMobileNumber(input);
                    response.msg = response.isValid ? "" : "Invalid Mobile Number !";
                    break;
                case ValidationType.Integer:
                    response.isValid = this.isInt(input);
                    response.msg = response.isValid ? "" : "Invalid Integer Number !";
                    break;
                case ValidationType.Float:
                    response.isValid = this.isFloat(input);
                    response.msg = response.isValid ? "" : "Invalid Float Number !";
                    break;
                case ValidationType.Pan:
                    response.isValid = this.isValidPan(input);
                    response.msg = response.isValid ? "" : "Invalid Pan Card !";
                    break;
                case ValidationType.Adhar:
                    response.isValid = this.isValidAdhar(input);
                    response.msg = response.isValid ? "" : "Invalid Adhar Card !";
                    break;
                case ValidationType.Password:
                    response.isValid = this.isValidPassword(input);
                    response.msg = response.isValid ? "" : "Password should be between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character !";
                    break;
                case ValidationType.Date:
                    response.isValid = this.isValidDate(input);
                    response.msg = response.isValid ? "" : "Invalid Date !";
                    break;
                case ValidationType.Select:
                    response.isValid = this.isValidSelectBoxValue(input);
                    response.msg = response.isValid ? "" : "Invalid Value !";
                    break;
                case ValidationType.MUISelect:
                    response.isValid = input?.toString() === " " ? false : true;
                    response.isValid = input?.length <= 1 ? false : true;
                    response.msg = response.isValid ? "" : "Invalid Value !";
                    break;
                case ValidationType.AutoComplete:
                    response.isValid = input?.toString() === " " ? false : true;
                    response.isValid = input?.length <= 1 ? false : true;
                    response.msg = response.isValid ? "" : "Invalid Value !";
                    break;
                case ValidationType.URL:
                    response.isValid = this.isUrl(input);
                    response.msg = response.isValid ? "" : "Invalid URL !";
                    break;
                default:
                    break;
            }
        }
        else {
            if (isRequired) {

                if(type === ValidationType.AutoComplete){
                    response.msg = `Please Select !`;
                }else if(type === ValidationType.MUISelect){
                    response.msg = `Please Select !`;
                }
                else{
                    response.msg = `${ValidationType[type]} is mandatory field !`;
                }
                
              
                response.isValid = false;
            }
        }
        return response;
    }

    public static validateInputElement(elementId: string, type: ValidationType, isRequired: boolean): IValidationResponse {
        const element = document.getElementById(elementId) as HTMLInputElement;
        const value = element.value;
        return this.validateInput(value, type, isRequired);
    }
    public static validateMUISelectElement(elementId: string, type: ValidationType, isRequired: boolean): IValidationResponse {
        // const element = document.getElementById(elementId)?.children[0] as HTMLInputElement;
        const element = document.getElementById(elementId)as HTMLInputElement;
        // const value = element?.innerText;
        const value = element?.value;

        console.log("elel",document.getElementById(elementId));
        console.log({value});
        
        // console.log("value",value,element);
        return this.validateInput(value, type, isRequired);
    }

    // public static validateMUISelectElement(elementId: string, type: ValidationType, isRequired: boolean): IValidationResponse {
    //     const element = document.getElementById(elementId)?.children[0] as HTMLInputElement;
    //     const value = element?.value;
    //     return this.validateInput(value, type, isRequired);
    // }
    public static validateSelectBox(elementId: string, isRequired: boolean): IValidationResponse {
        const element = document.getElementById(elementId) as HTMLSelectElement;
        const value = element?.options[element?.selectedIndex].value;
        return this.validateInput(value, ValidationType.Select, isRequired);
    }
    public static validateAutoComplete(elementId: string,type:any, isRequired: boolean): IValidationResponse {
        // const element = document.getElementById(elementId) as HTMLSelectElement;
        const element = document.getElementById(elementId) as HTMLSelectElement;

        const value = element?.value;
        console.log({element,value})
        return this.validateInput(value, type, isRequired);
    }
    private static handleValidation(response: IValidationResponse, errorSpanId: string, elementName?: string) {
        this.manageErrorSpan(response, errorSpanId, elementName);
        return response;
    }

    public static manageErrorSpan(response: IValidationResponse, errorSpanId: string, elementName?: string) {
        const element = document.getElementById(errorSpanId);
        if (element != null) {
            if (response.isValid) {
                element.innerText = "";
                element.classList.add("invalid-feedback");
            } else {
                element.innerText = elementName !== undefined ? response.msg.replace("Text", elementName) : response.msg;
                element.classList.remove("invalid-feedback");
            }
        }
    }
    public static validateElement(elementId: string, type: ValidationType, isRequired: boolean, errorSpanId?: string, elementName?: string): IValidationResponse {
        let response: IValidationResponse;
        if (type === ValidationType.Select) {
            response = this.validateSelectBox(elementId, isRequired);
        }
        else if (type === ValidationType.AutoComplete) {
            response = this.validateAutoComplete(elementId,type, isRequired);
        }
        else if (type === ValidationType.MUISelect) {
            response = this.validateMUISelectElement(elementId, type, isRequired)
        }
        else {
            response = this.validateInputElement(elementId, type, isRequired)
        }
        return errorSpanId ? this.handleValidation(response, errorSpanId, elementName) : response;
    }
    public static validateElementDropdown(elementId: string, type: ValidationType, isRequired: boolean, val: string, errorSpanId?: string, elementName?: string): IValidationResponse {
        let response: IValidationResponse;
        response = this.validateInput(val, type, isRequired);
        return errorSpanId ? this.handleValidation(response, errorSpanId, elementName) : response;

    }

    public static validateCommonDropDownValue = (index: number, value: string, data: any[], elementIdString: string, errorMsg: string, objectKey: string) => {
        let indexValue = -1;
        for (let i = 0; i < data.length; ++i) {
            if (value === data[i][objectKey]) {
                indexValue = i;
                break;
            }
        }
        let element = document.getElementById(index + elementIdString);
        if (indexValue !== -1) {
            if (element !== null) {
                data[index][objectKey] = "";
                element.innerText = errorMsg;
            }
        } else {
            if (element !== null) {
                element.innerText = "";
            }
        }
        return indexValue;
    }

    public static ValidateMuiSelectOnChange(elementId: string, value: string, errorSpanId: string) {
        if (value) {
            this.handleValidation({ isValid: true, msg: "" } as IValidationResponse, errorSpanId, elementId)
        }
    }
    public static validateFile(selectedFile: File | null) {
        let response: IValidationResponse = {
            isValid: true,
            msg: ""
        }

        if (!selectedFile) {
            response.msg = "Please select file for upload !";
            response.isValid = false;
        }
        ValidationUtiltiy.manageErrorSpan(response, "errorDiv-uploadFile", "Upload Company logo file ");
        return response;
    };
}
