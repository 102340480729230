import React, { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Card,
  FormControl,
  Grid,
  InputBase,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SelectFieldComponent from "../../common/SelectFieldComponent";
import { formatNumber } from "../../../utility/formateNumber";

const useStyles = makeStyles((theme) => ({
  input: {
    // border:"1px solid red",
    height: "100%",
    color: "inherit", // Text color
    width: "100%",
    backgroundColor: "transparent", // Remove background
    fontSize: "16px", // Set font size
    padding: "0", // Set padding
    "&:focus": {
      outline: "none", // Remove outline on focus
    },
  },
}));

const AircraftDetails = ({
  userInput,
  handleInputChange,
  cabinSpecs,
  handleChangeCabinSpecs,
  isEditable,
  aircraft,
}: any) => {
  const classes = useStyles();


  // alert(cabinSpecs?.isWifiAvailable)
  console.log("wifi avauiablabael ",cabinSpecs?.isWifiAvailable)
  // const parsedTechinicalSpecs= JSON.parse(aircraft.techinicalSpecs);
  // const parsedCabinSpecs=   JSON.parse(aircraft.cabinSpecs)
  // console.log("user input",userInput)
  return (
    <>
      {isEditable ? (
        <Grid container className="aircraft-details-container">
          <Grid item xs={12} className="details-table-container">
            <Grid container className="detail-item">
              <Grid item xs={3} className="item top-left">
                <Typography variant="body1" component="p">
                  Minimum Runway Length (m)
                </Typography>
              </Grid>
              <Grid item xs={9} className="item top-right">
                <InputBase
                  className={classes.input}
                  type="number"
                  //   placeholder="Brand Name"
                  name="aircraftMinimumRunwayLength"
                  value={userInput?.aircraftMinimumRunwayLength}
                  onChange={handleInputChange}
                  inputProps={{ "aria-label": "custom input" }}
                />
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item">
                <Typography variant="body1" component="p">
                  Per Hour Cost (INR)
                </Typography>
              </Grid>
              <Grid item xs={9} className="item">
                <InputBase
                  className={classes.input}
                  type="number"
                  //   placeholder="Brand Name"
                  name="aircraftPerHourCost"
                  value={userInput?.aircraftPerHourCost}
                  onChange={handleInputChange}
                  inputProps={{ "aria-label": "custom input" }}
                />
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item">
                <Typography variant="body1" component="p">
                  Aircraft Speed(km/hr)
                </Typography>
              </Grid>
              <Grid item xs={9} className="item">
                <InputBase
                  className={classes.input}
                  type="number"
                  //   placeholder="Brand Name"
                  name="aircraftSpeed"
                  value={userInput?.aircraftSpeed}
                  onChange={handleInputChange}
                  inputProps={{ "aria-label": "custom input" }}
                />
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item">
                <Typography variant="body1" component="p">
                Maximum guest capacity
                </Typography>
              </Grid>
              <Grid item xs={9} className="item">
                <InputBase
                  className={classes.input}
                  type="number"
                  //   placeholder="Brand Name"
                  name="passengerCapacity"
                  value={userInput?.passengerCapacity}
                  onChange={handleInputChange}
                  inputProps={{ "aria-label": "custom input" }}
                />
                {/* <Typography variant='body1' component='p'>
            BMW
          </Typography> */}
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item">
                <Typography variant="body1" component="p">
                  Seating capacity range
                </Typography>
              </Grid>
              <Grid item xs={9} className="item">
                <InputBase
                  className={classes.input}
                  type="text"
                    placeholder="Seating capacity range"
                  name="seatingCapacity"
                  value={userInput?.seatingCapacity}
                  onChange={handleInputChange}
                  inputProps={{ "aria-label": "custom input" }}
                />
                {/* <Typography variant='body1' component='p'>
            BMW
          </Typography> */}
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item">
                <Typography variant="body1" component="p">
                  Number of pilots
                </Typography>
              </Grid>
              <Grid item xs={9} className="item">
                <InputBase
                  className={classes.input}
                  type="number"
                  //   placeholder="Brand Name"
                  name="pilotCapacity"
                  value={userInput?.pilotCapacity}
                  onChange={handleInputChange}
                  inputProps={{ "aria-label": "custom input" }}
                />
                {/* <Typography variant='body1' component='p'>
            BMW
          </Typography> */}
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item">
                <Typography variant="body1" component="p">
                  Number of flight attendants
                </Typography>
              </Grid>
              <Grid item xs={9} className="item">
                <InputBase
                  className={classes.input}
                  type="number"
                  //   placeholder=""
                  name="crewCapacity"
                  value={userInput?.crewCapacity}
                  onChange={handleInputChange}
                  inputProps={{ "aria-label": "custom input" }}
                />
                {/* <Typography variant='body1' component='p'>
            BMW
          </Typography> */}
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item">
                <Typography variant="body1" component="p">
                  Number of lavatory
                </Typography>
              </Grid>
              <Grid item xs={9} className="item">
                <InputBase
                  className={classes?.input}
                  type="number"

                  //   placeholder="Brand Name"
                  name="numberOfLavatory"
                  value={cabinSpecs?.numberOfLavatory}
                  // onChange={handleInputChange}
                  onChange={handleChangeCabinSpecs}
                  inputProps={{ "aria-label": "custom input" }}
                />
                {/* <Typography variant='body1' component='p'>
            BMW
          </Typography> */}
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item">
                <Typography variant="body1" component="p">
                  Wifi available
                </Typography>
              </Grid>
              <Grid item xs={9} className="item">
                {/* <InputBase
      className={classes.input}
    //   placeholder="Brand Name"
      name="isWifiAvailable"
      value={cabinSpecs?.isWifiAvailable}
      // onChange={handleInputChange}
      onChange={handleChangeCabinSpecs}
      inputProps={{ 'aria-label': 'custom input' }}
    /> */}

                <FormControl fullWidth>
                  <Select
                    className={classes.input}
                    sx={{ padding: "0 10px" }}
                    // value={"No"}
                    // defaultValue={cabinSpecs?.isWifiAvailable}
                    value={cabinSpecs?.isWifiAvailable}
                    name="isWifiAvailable"
                    variant="standard"
                    disableUnderline
                    onChange={handleChangeCabinSpecs}
                    // onChange={handleSelectChange}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item bottom-left">
                <Typography variant="body1" component="p">
                  Luggage capacity (ft³)
                </Typography>
              </Grid>
              <Grid item xs={9} className="item bottom-right">
                <InputBase
                  className={classes.input}
                  //   placeholder="Brand Name"
                  name="luggageCapacity"
                  value={userInput?.luggageCapacity}
                  onChange={handleInputChange}
                  inputProps={{ "aria-label": "custom input" }}
                />
                {/* <Typography variant='body1' component='p'>
            BMW
          </Typography> */}
              </Grid>
            </Grid>

            <Grid container className="detail-item">
              <Grid item xs={3} className="item bottom-left">
                <Typography variant="body1" component="p">
                  Cabin height (ft)
                </Typography>
              </Grid>
              <Grid item xs={9} className="item bottom-right">
              <InputBase
                  className={classes?.input}
                  name="cabinHeight"
                  value={cabinSpecs?.cabinHeight}
                  onChange={handleChangeCabinSpecs}
                  inputProps={{ "aria-label": "custom input" }}
                />
              </Grid>
            </Grid>
            {/* <Grid container className="detail-item">
        <Grid item xs={3} className='item'>
          <Typography variant='body1' component='p'>
          Cabin specs
          </Typography>
        </Grid>
        <Grid item xs={9} className='item'>
        {/* <InputBase
      className={classes.input}
    //   placeholder="Brand Name"
      name="cabinSpecs"
      value={userInput.cabinSpecs}
      onChange={handleInputChange}
      inputProps={{ 'aria-label': 'custom input' }}
    // /> */}

            {/* //     </Grid>
    //   </Grid> */}
          </Grid>
        </Grid>
      ) : (
        <Grid container className="aircraft-details-container">
          <Grid item xs={12} className="details-table-container">
          <Grid container className="detail-item">
              <Grid item xs={3} className="item top-left">
                <Typography variant="body1" component="p">
                Minimum Runway Length (m)
                </Typography>
              </Grid>
              <Grid item xs={9} className="item top-right">
                {/* <InputBase
      className={classes.input}
    //   placeholder="Brand Name"
      name="passengerCapacity"
      value={userInput.passengerCapacity}
      onChange={handleInputChange}
      inputProps={{ 'aria-label': 'custom input' }}
    /> */}
                <Typography variant="body1" component="p">
                  {formatNumber(aircraft?.aircraftMinimumRunwayLength)}
                </Typography>
              </Grid>
            </Grid>
          <Grid container className="detail-item">
              <Grid item xs={3} className="item">
                <Typography variant="body1" component="p">
                Per Hour Cost (INR)
                </Typography>
              </Grid>
              <Grid item xs={9} className="item">
                {/* <InputBase
      className={classes.input}
    //   placeholder="Brand Name"
      name="passengerCapacity"
      value={userInput.passengerCapacity}
      onChange={handleInputChange}
      inputProps={{ 'aria-label': 'custom input' }}
    /> */}
                <Typography variant="body1" component="p">
                  {formatNumber(aircraft?.aircraftPerHourCost)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item">
                <Typography variant="body1" component="p">
                Aircraft Speed(km/hr)
                </Typography>
              </Grid>
              <Grid item xs={9} className="item">
                <Typography variant="body1" component="p">
                  {formatNumber(aircraft?.aircraftSpeed)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item">
                <Typography variant="body1" component="p">
                Maximum guest capacity
                </Typography>
              </Grid>
              <Grid item xs={9} className="item">
                {/* <InputBase
      className={classes.input}
    //   placeholder="Brand Name"
      name="passengerCapacity"
      value={userInput.passengerCapacity}
      onChange={handleInputChange}
      inputProps={{ 'aria-label': 'custom input' }}
    /> */}
                <Typography variant="body1" component="p">
                  {aircraft?.passengerCapacity}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item">
                <Typography variant="body1" component="p">
                Seating capacity range
                </Typography>
              </Grid>
              <Grid item xs={9} className="item">
                <Typography variant="body1" component="p">
                  {aircraft?.seatingCapacity}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item">
                <Typography variant="body1" component="p">
                  Number of pilots
                </Typography>
              </Grid>
              <Grid item xs={9} className="item">
                {/* <InputBase
      className={classes.input}
    //   placeholder="Brand Name"
      name="pilotCapacity"
      value={userInput.pilotCapacity}
      onChange={handleInputChange}
      inputProps={{ 'aria-label': 'custom input' }}
    /> */}
                <Typography variant="body1" component="p">
                  {aircraft.pilotCapacity}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item">
                <Typography variant="body1" component="p">
                  Number of flight attendants
                </Typography>
              </Grid>
              <Grid item xs={9} className="item">
                <Typography variant="body1" component="p">
                  {aircraft.crewCapacity}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item">
                <Typography variant="body1" component="p">
                  Number of lavatory
                </Typography>
              </Grid>
              <Grid item xs={9} className="item">
                <Typography variant="body1" component="p">
                  {aircraft?.cabinSpecs?.numberOfLavatory}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item">
                <Typography variant="body1" component="p">
                  Wifi available
                </Typography>
              </Grid>
              <Grid item xs={9} className="item">
                {/* <InputBase
      className={classes.input}
    //   placeholder="Brand Name"
      name="isWifiAvailable"
      value={cabinSpecs.isWifiAvailable}
      // onChange={handleInputChange}
      onChange={handleChangeCabinSpecs}
      inputProps={{ 'aria-label': 'custom input' }}
    /> */}
                <Typography variant="body1" component="p">
                  {aircraft?.cabinSpecs?.isWifiAvailable}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item bottom-left">
                <Typography variant="body1" component="p">
                  Luggage capacity (ft³)
                </Typography>
              </Grid>
              <Grid item xs={9} className="item bottom-right">
                {/* <InputBase
      className={classes.input}
    //   placeholder="Brand Name"
      name="luggageCapacity"
      value={userInput.luggageCapacity}
      onChange={handleInputChange}
      inputProps={{ 'aria-label': 'custom input' }}
    /> */}
                <Typography variant="body1" component="p">
                  {aircraft.luggageCapacity}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className="item">
                <Typography variant="body1" component="p">
                  Cabin height (ft)
                </Typography>
              </Grid>
              <Grid item xs={9} className="item">
                {/* <InputBase
      className={classes.input}
    //   placeholder="Brand Name"
      name="isWifiAvailable"
      value={cabinSpecs.isWifiAvailable}
      // onChange={handleInputChange}
      onChange={handleChangeCabinSpecs}
      inputProps={{ 'aria-label': 'custom input' }}
    /> */}
                <Typography variant="body1" component="p">
                  {aircraft?.cabinSpecs?.cabinHeight}
                </Typography>
              </Grid>
            </Grid>
      
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AircraftDetails;
