// import React, { useEffect, useRef, useState } from 'react'
// import { Grid,Typography, Card, Box, Button } from "@mui/material";
// import editIcon from "../../assets/icons/edit-icon.svg";
// import { toast } from 'react-toastify';
// import { serviceProvider } from '../../provider/serviceProvider';
// import  { Dayjs } from 'dayjs';
// import DateTimePickerComponent from '../common/DateTimePickerComponent';
// import InputFieldComponent from '../common/InputFieldComponent';
// import SelectFieldComponent from '../common/SelectFieldComponent';
// import ImageUploadComponent from '../common/ImageUploadComponent';
// import ButtonComponent from '../common/ButtonComponent';
// import S3 from "react-aws-s3-typescript";
// import { awsConfig } from "../../configuration/config.json";
// import { ValidationUtiltiy } from '../../utility/ValidationUtility';

// const initialFlightInput = {
//     active: true,
//     aircraftId: "",
//     description: "",
//     duration: "",
//     endAirportId: "",
//     endDt: "",
//     endMonth: "",
//     flightId: "",
//     flightType: "EXCLUSIVE",
//     headline: "",
//     imageUrlList: "",
//     offerList: "",
//     oldPrice: 0,
//     passengerCapacity: 0,
//     price: 0,
//     recordCreatedBy: "",
//     recordCreatedTs: "",
//     recordUpdatedBy: "",
//     recordUpdatedTs: "",
//     startAirportId: "",
//     startDt: "",
//     startMonth: ""
// };

// const AddOfferedFlight = () => {
//     const [userInput, setUserInput] = useState(initialFlightInput);
//     const [images, setImages] = useState([] as any);
//     const [imageURLS, setImageURLs] = useState<any>([]);
//     const [loader, setLoader] = useState(false)
//     const [startDateTime, setStartDateTime] = useState<Dayjs | null>(null);
//     const [arrivalDateTime, setArrivalDateTime] = useState<Dayjs | null>(null);
//     const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> = useRef(null);
//     const [airports, setAirports] = useState([]);
//     const [aircrafts, setAircrafts] = useState([]);
//     const [files, setFiles] = useState([]);


//     const handleStartDateTime = (newDate: Dayjs | null) => {
//         setStartDateTime(newDate);
//     }
//     const handleArrivalDateTime = (newDate: Dayjs | null) => {
//         setArrivalDateTime(newDate);
//     }

//     const emptyInputFields = () => {
//         setUserInput(initialFlightInput);
//         setImageURLs([]);
//         setImages([])
//     }

//     console.log(userInput)
//     const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, newValue:any) => {
//         const { name, value } = event.target;
//         setUserInput({ ...userInput, [name]: value, imageUrlList: JSON.stringify(imageURLS) })
//     }

//     const handleChooseFile = () => {
//         chooseFileRef.current?.click();
//     };

//     const handleFileChange = (event: any) => {
//         const fileObjs:[] = event.target.files;
        
//         if (!fileObjs) {
//           return;
//         }
      
//         const validFiles = Array.from(fileObjs).filter(fileObj => ValidationUtiltiy.isValidImg(fileObj["name"]));
      
//         if (validFiles.length === 0) {
//           toast.error("No valid files selected.");
//           return;
//         }
//       console.log({validFiles})
//         setFiles(validFiles);
      
//         validFiles.forEach((file:any, index) => {
//           const newFileName = "123" + "_" + file.name.replace(/\..+$/, "");
//           uploadImage(file, newFileName, index);
//         });
//       };
      
//       const uploadImage = (file:any, newFile:any, index:any) => {
//         const ReactS3Client = new S3(awsConfig);
//         ReactS3Client.uploadFile(
//           file,
//           awsConfig.offeredFlightsSubFolder + "/" + newFile
//         ).then((data) => {
//           if (data.status === 204) {
//             console.log(files)
//             const img= data.location
//             setImageURLs((prevImageURLs:any) => [...prevImageURLs, data.location]);
//             console.log(data.location)
           
//             // alert("heloo")

//             // console.log(imageURLS,"updatedImageURLs",updatedImageURLs,index)
//             if (index === files.length - 1) {
//               updateUserInput(JSON.stringify(imageURLS));
//                 toast.success("Flights Pictures Uploaded");
//             }
//           } else {
//             console.log("File Not Uploaded !!");
//             toast.error("Error Occurred");
//           }
//         }).catch((err)=>{
//             console.log({err})
//             toast.error("Something Went Wrong.")
//           })
//       };
//       console.log(imageURLS)    
    
//     const updateUserInput=(imageUrl:string)=>{
//       if (imageUrl !== "") setUserInput({...userInput,imageUrlList:imageUrl})
//     }

//     const handleAddOfferedFlight = () => {
//         const startDt = startDateTime!=null ? startDateTime.toISOString() : new Date().toISOString();
//         const endDt = arrivalDateTime!=null ? arrivalDateTime.toISOString() : new Date().toISOString();
//         const payload = { ...userInput, startDt, endDt }
//         setLoader(true)
//         serviceProvider.flightService.post(payload).then((res) => {
//             // console.log({ res })
//             setLoader(false)
//             if (res.responseCode === 200) {
//                 toast.success(res.message)
//                 // emptyInputFields()
//                 // setCabinSpecs()
//             }

//         }).catch((err) => {
//             toast.error(err.data.message || "Something Went Wrong !!")
//             setLoader(false)
//             //   emptyInputFields()
//             console.log({ err })
//         })
//     }


//     const getAllAirportsList = () => {
//         setLoader(true);
//         serviceProvider.airportService.getAllAirports().then((res) => {
//             // console.log({res})
//             if (res.responseCode === 200) {
//                 setAirports(res.data)
//             }
//             setLoader(false)

//         }).catch((err) => {
//             console.log({ err })
//             setLoader(false)
//         })
//     }
//     const getAllAircraftsList = () => {
//         setLoader(true);
//         serviceProvider.aircraftService.getAllAircrafts("NA").then((res) => {
//             // console.log({res})
//             setLoader(false)
//             if (res.responseCode === 200) {
//                 setAircrafts(res.data)
//             }

//         }).catch((err) => {
//             setLoader(false)
//             console.log({ err })
//         })
//     }

//     useEffect(() => {
//         getAllAirportsList();
//         getAllAircraftsList()
//     }, [])

//     return (
//         <Grid container className="single-aircraft-container">
//             <Grid item xs={12}>
//                 <Box className="edit-heading-container">
//                     <Typography variant="h6" component="h6" textAlign="right">Edit Details</Typography>
//                     <Button>
//                         <img src={editIcon} alt="edit icon" width="20px" />
//                     </Button>
//                 </Box>
//             </Grid>
//             <Grid item xs={12}>
//                 <Grid container spacing={3} className="aircraft-image-container">
//                     {imageURLS.map((imageSrc:any) => {
//                         return <Grid item xs={3}>
//                             <Card className='image-card'>
//                                 <img src={imageSrc} alt="img" width={"100%"} />
//                             </Card>
//                         </Grid>
//                     })}
//                     <Grid item xs={3}>
//                         <ImageUploadComponent
//                             chooseFileRef={chooseFileRef}
//                             handleChooseFile={handleChooseFile}
//                             handleFileChange={handleFileChange}
//                             isMultiple={true}
//                         />
//                     </Grid>

//                 </Grid>
//             </Grid>
//             <Grid item xs={12}>
//                 <Card className="aircraft-description-card">
//                     <Typography variant='body1' component='p' className='description-heading'>
//                         Headline
//                     </Typography>
//                     <InputFieldComponent
//                         isMultiline={true}
//                         minRows={1}
//                         name="headline"
//                         value={userInput.headline}
//                         onChange={(e:any) => handleInputChange(e, e.target.value)}
//                     />
//                 </Card>
//             </Grid>
//             <Grid item xs={12}>
//                 <Card className="aircraft-description-card">
//                     <Typography variant='body1' component='p' className='description-heading'>
//                         Description
//                     </Typography>
//                     <InputFieldComponent
//                         isMultiline={true}
//                         minRows={4}
//                         name="description"
//                         value={userInput.description}
//                         onChange={(e:any) => handleInputChange(e, e.target.value)}
//                     />
//                 </Card>
//             </Grid>
//             <Grid item xs={12} >
//                 <Grid container className='form'>
//                     <Grid item xs={12} className="modal-table-container">
//                         <Grid container className='heading'>
//                             <Grid item xs={12}>
//                                 <Typography variant='h6' component='h6'>Flight Details</Typography>
//                             </Grid>
//                         </Grid>
//                         <Grid container className="edit-detail-item">
//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Aircraft
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 <SelectFieldComponent
//                                     name="aircraftId"
//                                     value={userInput.aircraftId}
//                                     options={aircrafts}
//                                     onChange={(e:any) => handleInputChange(e, e.target.value)}
//                                     labelKey="name"
//                                     optionKey="aircraftId"
//                                 />
//                             </Grid>
//                         </Grid>
//                         <Grid container className="edit-detail-item">

//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Departure Airport
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 <SelectFieldComponent
//                                     name="startAirportId"
//                                     value={userInput.startAirportId}
//                                     options={airports}
//                                     onChange={(e:any) => handleInputChange(e, e.target.value)}
//                                     labelKey="name"
//                                     optionKey="airportId"
//                                 />
//                             </Grid>
//                         </Grid>
//                         <Grid container className="edit-detail-item">
//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Arrival Airport
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 <SelectFieldComponent
//                                     name="endAirportId"
//                                     value={userInput.endAirportId}
//                                     options={airports}
//                                     onChange={(e:any) => handleInputChange(e, e.target.value)}
//                                     labelKey="name"
//                                     optionKey="airportId"
//                                 />

//                             </Grid>
//                         </Grid>
//                         <Grid container className="edit-detail-item">
//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Start date and time
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 <DateTimePickerComponent
//                                     value={startDateTime}
//                                     onChange={handleStartDateTime}
//                                 />
//                             </Grid>
//                         </Grid>
//                         <Grid container className="edit-detail-item">
//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Arrival date and time
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 <DateTimePickerComponent
//                                     value={arrivalDateTime}
//                                     onChange={handleArrivalDateTime}
//                                 />
//                             </Grid>
//                         </Grid>
//                         <Grid container className="edit-detail-item">
//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Passenger Capacity
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 <InputFieldComponent
//                                     name="passengerCapacity"
//                                     value={userInput.passengerCapacity}
//                                     onChange={(e:any) => handleInputChange(e, e.target.value)}
//                                 />
//                             </Grid>
//                         </Grid>
//                         <Grid container className="edit-detail-item">
//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Duration
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 <InputFieldComponent
//                                     name="duration"
//                                     value={userInput.duration}
//                                     onChange={(e:any) => handleInputChange(e, e.target.value)}
//                                 />
//                             </Grid>
//                         </Grid>
//                         <Grid container className="edit-detail-item">
//                             <Grid item xs={3} className='edit-item dark-bg'>
//                                 <Typography variant='body1' component='p'>
//                                     Price
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={9} className='edit-item'>
//                                 <InputFieldComponent
//                                     name="price"
//                                     value={userInput.price}
//                                     onChange={(e:any) => handleInputChange(e, e.target.value)}
//                                 />
//                             </Grid>
//                         </Grid>
                        
//                     </Grid>
//                 </Grid>
//             </Grid>
//             <Grid item xs={12} className="btn-container">
//             {/* variant, className, onClick, label  */}
//                 <ButtonComponent fullWidth={false} variant="contained" className="rounded-btn-fill" label="Save" onClick={handleAddOfferedFlight} />
//             </Grid>
//         </Grid>
//     )
// }

// export default AddOfferedFlight;


import React, { useEffect, useRef, useState } from 'react'
import { Grid,Typography, Card, Box, Button, Tooltip, IconButton } from "@mui/material";
import editIcon from "../../assets/icons/edit-icon.svg";
import { toast } from 'react-toastify';
import { serviceProvider } from '../../provider/serviceProvider';
import  dayjs, { Dayjs } from 'dayjs';
import DateTimePickerComponent from '../common/DateTimePickerComponent';
import InputFieldComponent from '../common/InputFieldComponent';
import SelectFieldComponent from '../common/SelectFieldComponent';
import ImageUploadComponent from '../common/ImageUploadComponent';
import ButtonComponent from '../common/ButtonComponent';
import S3 from "react-aws-s3-typescript";
import { awsConfig } from "../../configuration/config.json";
import { ValidationUtiltiy } from '../../utility/ValidationUtility';
import { useNavigate } from 'react-router-dom';
import backArrow from "../../assets/icons/back-arrow.svg";
import AutoCompleteComponent from '../common/AutoCompleteComponent';
import { IAircraft } from '../../interface/IAircraft';
import { IAirport } from '../../interface/IAirport';
import SpinLoader from '../loader/SpinLoader';

const initialFlightInput = {
    active: true,
    aircraftId: "",
    description: "",
    duration: "",
    endAirportId: "",
    endDt: "",
    endMonth: "",
    flightId: "",
    flightType: "EXCLUSIVE",
    title:"",
    headline: "",
    imageUrlList: "",
    offerList: "",
    oldPrice: 0,
    passengerCapacity: 0,
    price: 0,
    startAirportId: "",
    startDt: "",
    startMonth: ""
};

const AddOfferedFlight = () => {
    const [userInput, setUserInput] = useState(initialFlightInput);
    const [images, setImages] = useState([] as any);
    const [imageURLS, setImageURLs] = useState<any>([]);
    const [loader, setLoader] = useState(false)
    const [startDateTime, setStartDateTime] = useState<any>(null);
    const [arrivalDateTime, setArrivalDateTime] = useState<any>(null);
    const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> = useRef(null);
    const [airports, setAirports] = useState([]);
    const [aircrafts, setAircrafts] = useState([]);
    const [files, setFiles] = useState([]);


    const navigate= useNavigate();


    const handleStartDateTime = (newDate: Dayjs | null) => {
        setStartDateTime(newDate);
    }
    const handleArrivalDateTime = (newDate: Dayjs | null) => {
        setArrivalDateTime(newDate);
    }

    const emptyInputFields = () => {
        setUserInput(initialFlightInput);
        setImageURLs([]);
        setImages([])
    }

    // console.log(userInput)
    const handleInputChange = (property:any, newValue:any) => {
        // const { name, value } = event.target;
        setUserInput({ ...userInput, [property]: newValue, imageUrlList: JSON.stringify(imageURLS) })
    }

    // const handleChooseFile = () => {
    //     chooseFileRef.current?.click();
    // };

    // const handleFileChange = (event: any) => {
    //     const fileObjs:[] = event.target.files;
        
    //     if (!fileObjs) {
    //       return;
    //     }
      
    //     const validFiles = Array.from(fileObjs).filter(fileObj => ValidationUtiltiy.isValidImg(fileObj["name"]));
      
    //     if (validFiles.length === 0) {
    //       toast.error("No valid files selected.");
    //       return;
    //     }
    //   console.log({validFiles})
    //     setFiles(validFiles);
      
    //     validFiles.forEach((file:any, index) => {
    //       const newFileName = "123" + "_" + file.name.replace(/\..+$/, "");
    //       uploadImage(file, newFileName, index);
    //     });
    //   };
      
    //   const uploadImage = (file:any, newFile:any, index:any) => {
    //     setLoader(true)
    //     const ReactS3Client = new S3(awsConfig);
    //     ReactS3Client.uploadFile(
    //       file,
    //       awsConfig.offeredFlightsSubFolder + "/" + newFile
    //     ).then((data) => {
    //         setLoader(false)
    //       if (data.status === 204) {
    //         console.log(files)
    //         const img= data.location
    //         setImageURLs((prevImageURLs:any) => [...prevImageURLs, data.location]);
    //         console.log(data.location)
           
    //         // alert("heloo")

    //         // console.log(imageURLS,"updatedImageURLs",updatedImageURLs,index)
    //         if (index === files.length - 1) {
    //           updateUserInput(JSON.stringify(imageURLS));
    //             toast.success("Flights Pictures Uploaded");
    //         }
    //       } else {
    //         console.log("File Not Uploaded !!");
    //         toast.error("Error Occurred");
    //       }
    //     }).catch((err)=>{
    //         console.log({err})
    //         setLoader(false)
    //         toast.error("Something Went Wrong.")
    //       })
    //   };
    //   console.log(imageURLS)    
    
    // const updateUserInput=(imageUrl:string)=>{
    //     alert("calleld")
    //   if (imageUrl !== "") setUserInput({...userInput,imageUrlList:imageUrl})
    // }


    const handleChooseFile = () => {
        // 👇️ open file input box on click of another element
        chooseFileRef.current?.click();
    };
    

    // new added
    const handleFileChange = (event: any) => {
        const fileObjs: [] = event.target.files;

        if (!fileObjs) {
            return;
        }

        const validFiles = Array.from(fileObjs).filter(fileObj => ValidationUtiltiy.isValidImg(fileObj["name"]));

        if (validFiles.length === 0) {
            toast.error("No valid files selected.");
            return;
        }

        const uploadPromises = validFiles.map((file: any, index) => {
            const newFileName = "123" + "_" + file.name.replace(/\..+$/, "");
            return uploadImage(file, newFileName);
        });
        setLoader(true)
        Promise.all(uploadPromises)
            .then((uploadedURLs) => {
                setLoader(false)
                setImageURLs((prevImageURLs: any) => [...prevImageURLs, ...uploadedURLs]);
                updateUserInput(JSON.stringify(uploadedURLs));
                // toast.success("Image Uploaded");
                toast.success("Image Uploaded Click Save Button to Update.");
            })
            .catch((error) => {
                setLoader(false)
                console.error(error);
                toast.error("Error Occurred");
            });
    };


    // new added

    const uploadImage = (file: any, newFile: any) => {
// setLoader(true)
        return new Promise((resolve, reject) => {
            const ReactS3Client = new S3(awsConfig);
            ReactS3Client.uploadFile(
                file,
                awsConfig.offeredFlightsSubFolder + "/" + newFile
            ).then((data) => {
                if (data.status === 204) {
                    resolve(data.location);
                } else {
                    reject("File Not Uploaded");
                }
                // setLoader(false)
            }).catch((err: any) => {
            //   setLoader(false)
                reject("Something Went Wrong.");
            });
        });
    };
    //   console.log({ files })

   
  

  const updateUserInput = (imageUrl: string) => {
    if (imageUrl !== "") setUserInput({ ...userInput, imageUrlList: imageUrl });
  };

    const handleAddOfferedFlight = () => {
        // const startDt = startDateTime!=null ? startDateTime.toISOString() : new Date().toISOString();
        // const endDt = arrivalDateTime!=null ? arrivalDateTime.toISOString() : new Date().toISOString();

        const startDt = startDateTime ? dayjs(new Date(startDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
        const endDt = arrivalDateTime ? dayjs(new Date(arrivalDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
        const payload = { ...userInput, startDt, endDt }
        setLoader(true)
        serviceProvider.flightService.post(payload).then((res) => {
            // console.log({ res })
            setLoader(false)
            if (res.responseCode === 200) {
                toast.success(res.message)
                window.location.href = "/offered-flight-management"
                // emptyInputFields()
                // setCabinSpecs()
            }else{
                toast.error(res?.message || "Something Went Wrong !!")
            }

        }).catch((err) => {
            toast.error(err?.data?.message || "Something Went Wrong !!")
            setLoader(false)
            //   emptyInputFields()
            console.log({ err })
        })
    }


    const getAllAirportsList = () => {
        // setLoader(true);
        serviceProvider.airportService.getAllAirports().then((res) => {
            // console.log({res})
            if (res.responseCode === 200) {
                setAirports(res.data)
            }
            // setLoader(false)

        }).catch((err) => {
            console.log({ err })
            // setLoader(false)
        })
    }
    const getAllAircraftsList = () => {
        // setLoader(true);
        serviceProvider.aircraftService.getAllAircrafts("NA").then((res) => {
            // console.log({res})
            // setLoader(false)
            if (res.responseCode === 200) {
                setAircrafts(res.data)
            }

        }).catch((err) => {
            // setLoader(false)
            console.log({ err })
        })
    }

    useEffect(() => {
        getAllAirportsList();
        getAllAircraftsList()
    }, []);

    if(loader){
        return <SpinLoader/>
    }

    return (
        <Grid container className="single-aircraft-container">
            {/* <Grid item xs={12}>
                <Box className="edit-heading-container">
                    <Typography variant="h6" component="h6" textAlign="right">Edit Details</Typography>
                    <Button>
                        <img src={editIcon} alt="edit icon" width="20px" />
                    </Button>
                </Box>
            </Grid> */}
              <Grid item xs={12}>
        <Grid container mb="10px">
          <Grid item xs={6}>
            <Box className="back-btn-container">
              <Tooltip title="back">
                <IconButton onClick={() => navigate(-1)}>
                  <img src={backArrow} alt="back" />
                </IconButton>
              </Tooltip>
              <Typography variant="h6" component="h6">
                Add Offered Flight
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={6}>
    <Box className="edit-heading-container">

 
<Typography variant="h6" component="h6" textAlign="right">Edit Details</Typography>
 <Button>
     <img src={editIcon} alt="edit icon" width="20px" />
 </Button>

</Box>
    </Grid> */}
        </Grid>
      </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3} className="aircraft-image-container">
                    {imageURLS.map((imageSrc:any) => {
                        return <Grid item xs={3}>
                            <Card className='image-card'>
                                <img src={imageSrc} alt="img" width={"100%"} />
                            </Card>
                        </Grid>
                    })}
                    <Grid item xs={3}>
                        <ImageUploadComponent
                            chooseFileRef={chooseFileRef}
                            handleChooseFile={handleChooseFile}
                            handleFileChange={handleFileChange}
                            isMultiple={true}
                        />
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Card className="aircraft-description-card">
                    <Typography variant='body1' component='p' className='description-heading'>
                        Title
                    </Typography>
                    <InputFieldComponent
                        // isMultiline={true}
                        // minRows={1}
                        type="text"
                        name="title"
                        value={userInput.title}
                        onChange={(e: any) => handleInputChange("title", e.target.value)}
                    />
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card className="aircraft-description-card">
                    <Typography variant='body1' component='p' className='description-heading'>
                        Headline
                    </Typography>
                    <InputFieldComponent
                        isMultiline={true}
                        minRows={1}
                        name="headline"
                        value={userInput.headline}
                        onChange={(e: any) => handleInputChange("headline", e.target.value)}
                    />
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card className="aircraft-description-card">
                    <Typography variant='body1' component='p' className='description-heading'>
                        Description
                    </Typography>
                    <InputFieldComponent
                        isMultiline={true}
                        minRows={4}
                        name="description"
                        value={userInput.description}
                        onChange={(e: any) => handleInputChange("description", e.target.value)}
                    />
                </Card>
            </Grid>
            <Grid item xs={12} >
                <Grid container className='form'>
                    <Grid item xs={12} className="modal-table-container">
                        <Grid container className='heading'>
                            <Grid item xs={12}>
                                <Typography variant='h6' component='h6'>Flight Details</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="edit-detail-item">
                            <Grid item xs={3} className='edit-item dark-bg'>
                                <Typography variant='body1' component='p'>
                                    Aircraft
                                </Typography>
                            </Grid>
                            <Grid item xs={9} className='edit-item'>
                                {/* <SelectFieldComponent
                                    name="aircraftId"
                                    value={userInput.aircraftId}
                                    options={aircrafts}
                                    onChange={(e:any) => handleInputChange(e, e.target.value)}
                                    labelKey="name"
                                    optionKey="aircraftId"
                                /> */}
                                <AutoCompleteComponent
                  options={aircrafts}
                  position="end"
                  // getOptionLabel={(option: any) => `${option.name} (${option.icaoCode})`}
                  getOptionLabel={(option: IAircraft) => `${option.name}`}
                  renderOption={(props: any, option: any) => (
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                      {...props}
                    >
                      {/* <Typography textAlign="left">{option.city}</Typography> */}
                      <Typography textAlign="left">{option.name}</Typography>
                    </Box>
                  )}
                  onChange={(e: any, newValue: any) =>
                    handleInputChange("aircraftId", newValue?.aircraftId)
                  }
                  placeholder="Select Aircraft"
                  // isIconVisible={true}
                >
                  {/* <img src={"destinationIcon"} alt="icon" height="25px" width="25px" /> */}
                </AutoCompleteComponent>
                            </Grid>
                        </Grid>
                        <Grid container className="edit-detail-item">

                            <Grid item xs={3} className='edit-item dark-bg'>
                                <Typography variant='body1' component='p'>
                                    Start Airport
                                </Typography>
                            </Grid>
                            <Grid item xs={9} className='edit-item'>
                                {/* <SelectFieldComponent
                                    name="startAirportId"
                                    value={userInput.startAirportId}
                                    options={airports}
                                    onChange={(e:any) => handleInputChange(e, e.target.value)}
                                    labelKey="name"
                                    optionKey="airportId"
                                /> */}
                                         <AutoCompleteComponent
                  options={airports}
                  getOptionLabel={(option: IAirport) =>
                    `${option.name} (${option.icaoCode}) - ${option.city}`
                  }
                  renderOption={(props: any, option: any) => (
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        display:"flex",
                        flexDirection:"column",
                        justifyContent:"flex-start",
                        alignItems:"flex-start",
                      }}
                      {...props}
                    >
                      <Typography textAlign="left">{option.city}</Typography>
                      <Typography textAlign="left">
                        {option.name} ({option.icaoCode})
                      </Typography>
                    </Box>
                  )}
                  position="end"
                  onChange={(e: any, newValue: any) =>
                    handleInputChange("startAirportId", newValue?.airportId)
                  }
                  placeholder="Select Start Airport"
                  // isIconVisible={true}
                >
                  {/* <img src={"destinationIcon"} alt="destination-icon" height="25px" width="25px" /> */}
                </AutoCompleteComponent>
                            </Grid>
                        </Grid>
                        <Grid container className="edit-detail-item">
                            <Grid item xs={3} className='edit-item dark-bg'>
                                <Typography variant='body1' component='p'>
                                    End Airport
                                </Typography>
                            </Grid>
                            <Grid item xs={9} className='edit-item'>
                                {/* <SelectFieldComponent
                                    name="endAirportId"
                                    value={userInput.endAirportId}
                                    options={airports}
                                    onChange={(e:any) => handleInputChange(e, e.target.value)}
                                    labelKey="name"
                                    optionKey="airportId"
                                /> */}

<AutoCompleteComponent
                  options={airports}
                  position="end"
                  getOptionLabel={(option: IAirport) =>
                    `${option.name} (${option.icaoCode}) - ${option.city}`
                  }
                  renderOption={(props: any, option: any) => (
                    <Box
                      sx={{
                        width: "100%",
                        display:"flex",
                        flexDirection:"column",
                        justifyContent:"flex-start",
                        alignItems:"flex-start",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                      {...props}
                    >
                      <Typography textAlign="left">{option.city}</Typography>
                      <Typography textAlign="left">
                        {option.name} ({option.icaoCode})
                      </Typography>
                    </Box>
                  )}
                  onChange={(e: any, newValue: any) =>
                    handleInputChange("endAirportId", newValue?.airportId)
                  }
                  placeholder="Select End Airport"
                  // isIconVisible={true}
                >
                  {/* <img src={destinationIcon} alt="destination-icon" height="25px" width="25px" /> */}
                </AutoCompleteComponent>

                            </Grid>
                        </Grid>
                        <Grid container className="edit-detail-item">
                            <Grid item xs={3} className='edit-item dark-bg'>
                                <Typography variant='body1' component='p'>
                                    Start date and time
                                </Typography>
                            </Grid>
                            <Grid item xs={9} className='edit-item'>
                                <DateTimePickerComponent
                                    value={startDateTime}
                                    onChange={handleStartDateTime}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className="edit-detail-item">
                            <Grid item xs={3} className='edit-item dark-bg'>
                                <Typography variant='body1' component='p'>
                                    End date and time
                                </Typography>
                            </Grid>
                            <Grid item xs={9} className='edit-item'>
                                <DateTimePickerComponent
                                    value={arrivalDateTime}
                                    onChange={handleArrivalDateTime}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className="edit-detail-item">
                            <Grid item xs={3} className='edit-item dark-bg'>
                                <Typography variant='body1' component='p'>
                                    Guest Capacity
                                </Typography>
                            </Grid>
                            <Grid item xs={9} className='edit-item'>
                                <InputFieldComponent
                                    name="passengerCapacity"
                                    type="number"
                                    value={userInput.passengerCapacity}
                                    onChange={(e:any) => handleInputChange("passengerCapacity", e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className="edit-detail-item">
                            <Grid item xs={3} className='edit-item dark-bg'>
                                <Typography variant='body1' component='p'>
                                    Duration
                                </Typography>
                            </Grid>
                            <Grid item xs={9} className='edit-item'>
                                <InputFieldComponent
                                    name="duration"
                                    type="number"
                                    value={userInput.duration}
                                    onChange={(e:any) => handleInputChange("duration", e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className="edit-detail-item">
                            <Grid item xs={3} className='edit-item dark-bg'>
                                <Typography variant='body1' component='p'>
                                    Price
                                </Typography>
                            </Grid>
                            <Grid item xs={9} className='edit-item'>
                                <InputFieldComponent
                                    name="price"
                                    type="number"
                                    value={userInput.price}
                                    onChange={(e:any) => handleInputChange("price", e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className="btn-container">
            {/* variant, className, onClick, label  */}
                <ButtonComponent fullWidth={false} variant="contained" className="rounded-btn-fill" label="Save" onClick={handleAddOfferedFlight} />
            </Grid>
        </Grid>
    )
}

export default AddOfferedFlight;






